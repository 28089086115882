@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:wght@400;600;700&display=swap');

* {
  box-sizing: border-box;
}

:root {
  --primary-color: #f88020;
  --secondary-color: #f6e1e1;
  --main-color: #d1284c;
  --option-color: #3e0e1f;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* Navbar */
.navbar {
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.navbar .navbar-brand {
  margin-left: 10px;
  font-size: 30px;
  font-family: "Pacifico", cursive;
  /* background-color: red; */
  color: #fff;
  text-decoration: none;
}

.navbar .navbar-brand:hover {
  color: var(--option-color);
}

.navbar .navbar-brand img {
  margin-right: 10px;
}

.navbarNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}

.navbarNav .nav-link {
  margin-right: 50px;
}

.navbarNav .btn {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  background-color: #fff;
  color: var(--option-color);
}

.navbarNav .btn:hover {
  background-color: var(--option-color);
  color: #fff;
}

.navbarNav .btn-logout:hover {
  background-color: var(--main-color);
}

/* carousel */
.overlay {
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0 ,0 ,0 , 1);
  z-index: 1000;
}

.foodiezy-logo {
  position: absolute;
    right: 15%;
    bottom: 6.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

.foodiezy-logo h1{
  font-family: "Pacifico", cursive;
  font-weight: 500;
  font-size: 70px;
  margin: 30px;
}

.foodiezy-logo p {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
}

.carousel-item {
  object-fit: cover;
  opacity: 0.5;
}

.carousel-caption input:focus{
  outline: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
